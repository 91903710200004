@import "../shared/style/main";

.container-card {
  display: flex;
  justify-content: flex-start;
  min-width: 247px;

  &.error {
    border: 1px solid red;
  }

  @include tablet-down {
    flex-direction: column;
    align-items: center;
    & > .containerRoot {
      margin-bottom: 1rem;
      margin-right: 0;
    }
  }

  .container-root {
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 4px;
    background-color: #ffffff;
    max-width: 300px;
    cursor: pointer;
    margin-right: $spacing-single;
    border: 1px solid rgba(211, 211, 211, 0.4);

    &:last-of-type {
      margin-right: 0;
    }

    @include mobile {
      margin-right: 0;
      width: 100%;
      margin-bottom: $spacing-double;
      min-width: auto;
    }

  }

  .container-info {
    margin: $spacing-single;
  }

  .container-heading {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: inherit;
    padding: $spacing-half;
    text-align: center;
    color: $okcomply-blue;
    font-size: 20px;
    font-weight: normal;
    line-Height: 20px;
  }

  .container-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: $spacing-half;
    width: 100%;
    font-size: 14px;
    line-height: 16px;

    div {
      display: flex;
      justify-content: center;
      padding-bottom: 8px;

      span {
        padding-left: 8px;
      }

    }

    .button  {
      margin-left: 0;
    }

  }

  .container-subheading {
    display: flex;
    justify-content: flex-start;
    width: inherit;
    padding: $spacing-half;
    font-size: 12px;
    color: black;
  }

  .readMore {
    font-size: 12px;
    font-weight: bold;
    color: $okcomply-blue;
  }

  .container-radiobutton {
    display: flex;
    margin-right: 0;
    padding-right: 0;
    justify-content: flex-start;
    height: 30px;

    &.no-selection {
      display: none !important;
      height: 0;
    }

  }

  .container-image {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    flex: 1;
    padding-top: 8px;
  }

  .container-img {
    display: flex;
    justify-content: center;
    min-height: 100px;
    min-width: 180px;
    margin: 16px;
  }

  .img {
    height: 108px;
    width: 98px
  }

  .img2 {
    height: 101px;
    width: 46px;
    margin-top: 7px;
  }

  .selected {
    border: 2px solid $okcomply-blue;
  }

  .button {
    margin: 16px;
  }

  .module-active {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 10px;
    top: 10px;
    z-index: 1;
    width: 80px;
    height: 24px;
    border-radius: 4px;
    background-color: #006280;
    overflow: hidden;
    color: white;
    font-size: 10px;
  }

}