@import "../shared/style/main";

$list-item-box-shadow: 0 2px 2px 0 rgba(230, 230, 230, 0.8);

.okcomply-list-item {
  background-color: $okcomply-white;
  margin-bottom: 1rem;
  padding-bottom: 0;

  &:hover {
    box-shadow: $list-item-box-shadow;
    -moz-box-shadow: $list-item-box-shadow;
    -webkit-box-shadow: $list-item-box-shadow;
  }

  .list-item-rows {
    padding: 1rem;
    display: flex;
    flex-direction: row;
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }
  .list-item-row-single {
    display: flex;
    flex: 1;
    flex-direction: row;
    &.reverse-direction {
      flex-direction: row-reverse;
    }
    &.wide {
      width: 250px;
      max-width: 250px;
      min-width: 250px;
    }
    &:first-of-type {
      @media screen and (max-width: 768px) {
        border-bottom: solid 1px $okcomply-gray;
        margin-bottom: 0.5rem;
        padding-bottom: 0.5rem;
      }
    }
  }
  .list-item-check {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .list-item-label-with-tooltip {
    position: relative;
    flex: 1;
    .tooltip-desktop {
      right: 0;
      top: 3.3rem;
      font-weight: 400;
      // font-family: $text-font-stack;
    }
    &:hover {
      color: $okcomply-blue;
      .tooltip-desktop {
        display: block;
      }
    }
  }

  .task-deadline {
    //negates default padding for phasing
    margin-left: -0.5rem;
  }

  .list-item-label,
  .list-item-executor {
    flex: 1;
    align-self: center;
    @include tablet-up {
      span.has-padding {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
      }
    }
    .is-flex {
      align-items: center;
    }
    span.has-padding-right {
      padding-right: 10px;
    }
  }

  .list-item-icons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    a {
      color: $okcomply-black;
      &:hover {
        color: $okcomply-blue;
      }
    }
  }
}

.list-item-documents {
  position: relative;
  border-radius: 5px;
  background-color: $okcomply-blue;
  .documents {
    margin: 0 1rem;
  }
  .list-item-documents-header {
    display: flex;
    justify-content: space-between;
    margin-left: 1rem;
    margin-right: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;

    h4 {
      margin: 0;
      padding: 0;
    }
  }
}
