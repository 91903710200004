@import "../shared/style/main";

$list-item-box-shadow: 0 2px 2px 0 rgba(230, 230, 230, 0.8);

.select-role {

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &.error {
    border: 1px solid red;
  }

  @include tablet-down {
    flex-direction: column;
    align-items: center;
    & > .containerRoot {
      margin-bottom: 1rem;
      margin-right: 0;
    }
  }

  .containerRoot {
    display: flex;
    flex-direction: column;
    flex: 1;
    //  max-width: 247px;
    border-radius: 4px;
    background-color: #ffffff;
    opacity: 0.6 !important;
    cursor: pointer;
    flex-basis: 100%;
    margin-right: $spacing-single;
    border: 1px solid rgba(211, 211, 211, 0.4);

    &:last-of-type {
      margin-right: 0;
    }

    @include mobile {
      margin-right: 0;
      width: 100%;
      min-width: auto;
    }

  }

  .containerHeading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
    color: $okcomply-blue;
    font-size: 16px;
    font-weight: bolder;
    line-Height: 20px;
    padding-top: 8px;
    padding-left: 8px;
    padding-right: 8px;
  }

  .containerText {
    display: flex;
    flex-direction: column;
    padding: 16px;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    font-size: 14px;
    line-height: 16px;

    div {
      display: flex;
      justify-content: center;
      padding-bottom: 8px;

      span {
        padding-left: 8px;
      }

    }
  }

  .containerRadioButton {
    display: flex;
    margin-right: 0;
    padding-right: 0;
    justify-content: flex-start;
    height: 30px;

    &.no-selection {
      display: none !important;
      height: 0;
    }

  }

  .containerImage {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    flex: 1;
    padding-top: 8px;
  }

  .containerImg {
    display: flex;
    justify-content: center;
    height: 140px;
    background-size: cover;
    background-position: center center;
  }

  .img {
    height: 108px;
  }

  .img2 {
    height: 101px;
    width: 46px;
    margin-top: 7px;
  }

  .img3 {
    height: 140px;
  }

  .selected {
    opacity: 1 !important;
    border: 1px solid $okcomply-blue;
  }

}
