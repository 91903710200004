@import "../shared/style/main";


.users-flow {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 4rem); 

  @include mobile {
    justify-content: center;
  }

  .users-page {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 96px;

    @include tablet-down {
      margin: 96px $spacing-double 48px $spacing-double;
    }

    .users-page-header {
      display: flex;
      flex-direction: row;

      @include mobile {
        flex-direction: column;
        flex: 0 1;
      }

      .title {
        display: inline-flex;
        justify-content: flex-start;
        flex: 1;
        color: $okcomply-blue;
      }

      .subTitle {
        font-size: 24px;
        color: black;
      }

    }

    .header {
      display: flex;
      flex-direction: row;
      flex: 1;

      div {
        display: flex;
        flex: 1;
        justify-content: flex-end;
      }


    }

    .users-page-footer {
      display: flex;
      justify-content: flex-end;
      flex: 1;

      @include tablet-down {
        flex: 1;
        justify-content: flex-end;
        flex-direction: column;
        margin-right: 0;
        padding: $spacing-single;

        .button {
          width: 100%;
          margin-bottom: $spacing-single;
        }

      }
    }

    .number-items {
      display: inline-flex;
      align-items: center;
      background-color: $okcomply-blue;
      border-radius: 3px;
      color: $okcomply-white;
      height: 2em;
      justify-content: center;
      line-height: 1.5;
      padding-left: 0.75em;
      padding-right: 0.75em;
      white-space: nowrap;
      margin-left: 0.5rem;
    }

    .header {
      margin-bottom: $spacing-single;
      font-weight: bold;
      color: black;
    }

    .users-list {
      flex-direction: row;
    }

    .invites-list {
      flex-direction: row;
    }

    h5 {
      margin-bottom: $spacing-single;
    }

    .container-users-navigation {
      display: flex;
      justify-content: space-between;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }
}
