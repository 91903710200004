.select {
  width: 100%;
  select {
    border-radius: 0;
    font-weight: 600;
    color: $okcomply-blue;
  }
}

.select:not(.is-multiple) {
  min-height: 2.5em !important;
  height: inherit;
}

.select {
  &:not(.is-multiple):not(.is-loading)::after {
    border-color: $okcomply-blue;
  }
  select {
    &:focus, &.is-focused, &:active, &.is-active {
      border-color: #3273dc;
      box-shadow: 0 0 0 0;
    }
  }
}