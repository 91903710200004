@import "shared/style/main.scss";

.onboarding-landing {
  display: flex;

  @include mobile {
    flex-direction: column;
  }

  .passwordstrengtherror {
    font-size: 15px;
    color: black;

    >span {
      padding-right: 0.5rem;
    }
  }

  .markdown-view {
    p, strong, li {
      color: white;
    }
    a {
      color: white;
      text-decoration: underline;
    }
  }

  .form-landing {
    display: flex;
    position: relative;
    flex: 1;
    justify-content: center;
    align-items: center;

    @include mobile {
      position: absolute;
      top: 1rem;
      left: 1rem;
      right: 1rem;
    }
  }

  .sidebar-landing {
    flex: 0 0 400px;
    min-height: 100vh;
    padding: 4rem;
    background-color: $okcomply-blue;

    @include mobile {
      display: none;
    }

    p {
      color: $okcomply-white;
    }
  }

  .sidebar-logo {
    width: 160px;
    height: auto;
  }

  .wrong-organisation {
    color: $okcomply-gray-darkest;
    text-decoration: underline;

    span {
      margin-left: 1rem;
    }
  }

  .password-reset-link {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    flex: 1;
    color: $okcomply-blue;
    text-decoration: underline;
    cursor: pointer;
  }

  .password-reset {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 1;
    color: $okcomply-blue;
    .input-text {
      width: 100%;
    }
    .header {
      padding-bottom: 1rem;
    }

    .text {
      padding-left: 1rem;
      padding-right: 1rem;
      display: flex;
      font-size: 16px;
      align-items: center;
      flex-direction: row;
      justify-content: left;
      flex: 1;
      color: $okcomply-blue;

      div:first-child {
        padding-right: 1rem;
      }
    }

    .back {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1rem;
      flex: 1;
      color: $okcomply-blue;
      text-decoration: none;
      padding-top: 1rem;

      span:nth-child(2) {
        &:hover {
          text-decoration: underline;
        }

      }
    }

  }

  .container-image {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;

    img {
      width: 50%;
      height: auto;
      margin-bottom: 1rem;
    }
  }

}

.ok-confirm {
  .container-card-centered {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    padding: 2rem;

    h3 {
      color: $okcomply-blue-light;
    }

    p {
      color: $okcomply-blue;
      font-size: 16px;
      text-align: center;
    }

    img {
      width: 50%;
      max-width: 120px;
      height: auto;
      margin-bottom: 1rem;
    }
  }
}