ul.okcomply-role-bullets {

  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 12px;

  li {
    display: flex;
  }

  li.okcomply-role-bullet {
    > span {

      &:nth-of-type(1) {
        color:  #33de8e;
      }

      &:nth-of-type(2) {
        display: inline-block;
        margin-left: 1rem;
        text-align: left;
        word-wrap: break-word;
      }
    }

  }
}