
.MuiAccordion-root {
  margin-bottom: 1rem;
  background-color: white !important;
}

.MuiAccordion-rounded {
  border-radius: 0 !important;
}


.MuiAccordionDetails-root {
  display: flex;
  margin: 2rem;
}

.MuiCollapse-wrapper {
  border-top: 1px solid rgba(211, 211, 211, 0.7);
  margin-bottom: 1rem;
}

.MuiPaper-root {
  color: $okcomply-blue !important;
  font-weight: bold;
}