.tag-remove {
  color:#a0a0a0;
  cursor:pointer;
  background-color:transparent;
  border:none;
  outline:none
}
.tag-remove.readOnly{
  cursor:not-allowed
}

.tag-list{
  display:inline;
  padding:0;
  margin:0
}

.tag-item{
  display:inline-block;
  margin:4px
}

.tag-item .search{
  border:none;
  outline:none
}

.tag-item:last-child{
  margin-right:4px
}

.node{
  list-style:none;
  white-space:nowrap;
  padding:4px
}

.node.leaf.collapsed{
  display:none
}

.node.disabled>*{
  color:gray;
  cursor:not-allowed
}

.node.match-in-children.hide .node-label{
  opacity:.5
}

.node>label {
  cursor:pointer;
  margin-left:2px
}

.toggle{
  white-space:pre;
  margin-right:4px;
  cursor:pointer
}

.toggle:after{
  content:" "
}

.toggle.collapsed:after {
  content:"+"
}

.toggle.expanded:after {
  content:"-"
}

.searchModeOn .toggle {
  display:none
}
.checkbox-item {
  vertical-align:middle;
  margin:0 4px 0 0
}

.checkbox-item.simple-select{
  display:none
}

.hide:not(.match-in-children){
  display:none
}

.react-dropdown-tree-select .dropdown{
  position:relative;
  width: 100%;
  height: 100%;
  display:table;
  background-color: white;
}


.react-dropdown-tree-select .dropdown .dropdown-trigger{
  line-height:20px;
  max-height:200px;
  width: 100%;
  display: table;
  overflow:auto;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.disabled{
  cursor:not-allowed
}
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.disabled.bottom:after{
  color:#b9b9b9
}

.react-dropdown-tree-select .dropdown .dropdown-content{
  position:absolute;
  padding:4px;
  width: 100%;
  z-index:32;
  background:#fff;
  border-top:1px solid rgba(0,0,0,.05);
  -webkit-box-shadow:0 5px 8px rgba(0,0,0,.15);
  box-shadow:0 5px 8px rgba(0,0,0,.15)
}

.react-dropdown-tree-select .dropdown .dropdown-content ul{
  margin:0;
  padding:0
}

.react-dropdown-tree-select .search {
  vertical-align: text-top;
  font-size: 1rem;
  font-weight: 600;
}

.react-dropdown-tree-select .toggle {
  vertical-align: middle;
  font-size: 1.25em;
  font-weight: 400;
}

.react-dropdown-tree-select .toggle.expanded::after {
  font-family: "Font Awesome\ 5 Free";
  font-weight: 900;
  font-style: normal;
  cursor: pointer;
  content: "\f107";
}

.react-dropdown-tree-select .toggle.collapsed::after {
  font-family: "Font Awesome\ 5 Free";
  font-weight: 900;
  font-style: normal;
  cursor: pointer;
  content: "\f105";
}

.checkbox-item {
  position: relative;
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.75rem;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: 0;
  vertical-align: middle!important;
}

.checkbox-item:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  border: .1rem solid #dbdbdb;
  border-radius: 4px;
}

.react-dropdown-tree-select .checkbox-item:checked {
  box-sizing: border-box;
  border-top: 0;
  border-left: 0;
  text-align: center;
}
.react-dropdown-tree-select .checkbox-item:checked::after {
  font-family: "Font Awesome\ 5 Free";
  font-weight: 900;
  font-style: normal;
  vertical-align: sub;
  color: #00d1b2;
  cursor: pointer;
  content: "\f00c";
}