.button.spaced-right {
  float: right;
}

.page-divider {
  background-color: rgba(211, 211, 211, 0.7);
  width: 100%;
  height: 1px;
  margin-top: $spacing-single;
  margin-bottom: $spacing-double;
}