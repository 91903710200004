@import "./main.scss";

body {
    // Simple columns implementation without
    // adding additional spacing
    .cols {
        @include tablet-up {
            display: flex;
            margin-left: -0.5rem;
            margin-right: -0.5rem;
            .col {
                flex: 1;
                margin-left: 0.5rem;
                margin-right: 0.5rem;
            }
        }
    }
    h1 {
        font-size: 40px;
        line-height: 40px;
        word-wrap: break-word;
        color: white;
        margin-bottom: 2rem;
        @include tablet {
            font-size: 30px;
            margin-bottom: 1rem;
        }
        @include mobile {
            font-size: 25px;
            margin-bottom: 0.5rem;
        }
    }
    .container-buttons {
        display: flex;
        justify-content: flex-end;

        @include mobile {
            justify-content: space-between;
            .button {
                flex: 1;
            }
        }
    }
}
.divider {
    margin-top: 1rem;
    margin-bottom: 2rem;
    border-bottom: solid 1px $okcomply-gray;
}

#auth0-login-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @include mobile {
        position: relative;
        width: 100%;
        top: unset;
        left: unset;
        transform: unset;
    }
}
